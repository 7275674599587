<template>
  <section class="section">
    <nav class="level is-vcentered">
      <div class="level-left">
        <div class="level-item">
          <img class="level-item-img" src="@/assets/icon/servicios-level.png" alt="Servicios">
          <h1 class="title">Servicios</h1>
        </div>
      </div>
    </nav>
    <main class="inner-section">
      <div class="container">
        <div class="box">
          <div class="columns is-multiline">
            <!-- Acciones -->
            <div class="column is-12">
              <div class="columns is-multiline is-vcentered">
                <!-- Buscador -->
                <div class="column is-3">
                  <div class="field">
                    <div class="control has-icons-right">
                      <input
                        class="input is-rounded input_ibs"
                        type="text"
                        placeholder="Buscar"
                        v-model="searchText"
                        @input="searchUser"
                      >
                      <span class="icon is-right">
                        <img class="icon_search" src="@/assets/icon/search.png">
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Select -->
                <div class="column is-3">
                  <v-select
                    class="input_select"
                    label="name"
                    placeholder='Tipo de Servicio'
                    v-model="statusSelected"
                    :reduce="status => status.id"
                    :options="serviceList"
                    @input="setStatus"
                  >
                  </v-select>
                </div>
                <div class="column is-6">
                  <button
                    class="is-pulled-right button button_ibs is-rounded"
                    @click="$router.push({ name: 'nuevo-servicio' })"
                  >
                    Solicitar Servicio
                </button>
                </div>
              </div>
            </div>
            <!-- Datos -->
            <div class="column is-12">
              <base-table
                class="is-striped-2 is-fullwidth is-hoverable"
                :columns="columnsData"
                :data="servicesList"
                :loading=loading
              >
                <!-- <div
                  slot="config"
                  slot-scope="scope"
                >
                  <div class="is_btn_status" :class="{ 'is_btn_status_process' : scope.row.service_status_id === inProcess }">
                    {{ scope.row.service_status_id === inProcess ? 'En Proceso' : 'Terminado' }}
                  </div>
                </div> -->
                <div
                  slot="action"
                  slot-scope="action"
                  class="icon_table_content"
                >
                  <img class="icon_table_action" src="@/assets/icon/upload_icon_grey.png" alt="download" @click="downloadService(action.row.id)">
                  <img class="icon_small" src="@/assets/icon/link_icon_grey.png" alt="link" @click="getLinkService(action.row.id)">
                  <img class="icon_table_action" src="@/assets/icon/share_icon_grey.png" alt="share" @click="shareService(action.row.id)">
                </div>
              </base-table>
            </div>
            <div class="column is-12">
              <div class="columns is-multiline is-vcentered">
                <div class="column is-12">
                  <base-pagination
                    :page="page"
                    :pages="parseInt(pages)"
                    :action="paginator"
                    :loading="loading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import service from '@/constants/catalogs/services'
import moment from 'moment'

const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Services',
  components: {
    BaseTable: () => import('@/components/Table.vue'),
    BasePagination: () => import('@/components/BasePagination.vue')
  },
  data () {
    return {
      inProcess: 1,
      page: 1,
      pages: 1,
      loading: false,
      searchText: '',
      statusSelected: '',
      servicesList: [],
      columnsData: [
        {
          id: 'reference',
          header: 'Referencia Interna',
          class: 'column-mobile',
          accessor: (row) => `${row.reference}`,
          link: true,
          type: 'link',
          route: (row) => ({ name: 'detalle-servicio', params: { serviceId: row.id } })
        },
        { id: 'serviceName', header: 'Servicio', class: 'is-hidden-mobile' },
        { id: 'po_number', header: 'PO', class: 'is-hidden-mobile' },
        { id: 'inspection_date', header: 'Fecha Inspección', class: 'is-hidden-mobile', accessor: (row) => moment(row.inspection_date).format('DD-MM-YYYY') },
        { id: 'container_number', header: 'No. Contenedor', class: 'column-mobile is-hidden-touch' },
        { id: 'providerName', header: 'Proveedor', class: 'column-mobile is-hidden-touch' }
        // { id: 'config', name: 'config', header: 'Estatus', class: 'is-hidden-mobile' },
        // { id: 'action', name: 'action', header: 'Acciones', class: 'has-text-centered' }
      ],
      serviceList: service
    }
  },
  methods: {
    ...mapActions(['getServices']),
    getServicesList (page, search, status = '') {
      this.getServices({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search, status: status })
        .then((resp) => {
          this.pages = resp.totalPages
          this.servicesList = resp.services
          this.loading = false
        })
    },
    searchUser ({ target }) {
      this.page = 1
      this.loading = true
      if (target.value.length >= 3 || target.value.length === 0) {
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push({ name: 'servicios', query: { page: this.page, search: target.value, status: this.statusSelected } })
        this.getServicesList(this.page, target.value, this.statusSelected)
      }
    },
    setStatus (status) {
      if (status === null) { status = '' }
      this.statusSelected = status
      this.page = 1
      this.loading = true
      this.$router.push({ name: 'servicios', query: { page: this.page, search: this.searchText, status: this.statusSelected } })
      this.getServicesList(this.page, this.searchText, status)
    },
    paginator (page) {
      this.page = page
      this.$router.push({ name: 'servicios', query: { page: this.page, search: this.searchText, status: this.statusSelected } })
      this.loading = true
      this.getServicesList(this.page, this.searchText, this.statusSelected)
    },
    // @TODO: Implementar servicios de descarga, obtencion de url y compartir
    downloadService () {
    },
    getLinkService () {
    },
    shareService () {
    }
  },
  beforeMount () {
    this.loading = true

    const { page, search, status } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.searchText = search === '' ? this.searchText : search
    this.statusSelected = !status ? this.statusSelected : status
    this.getServicesList(this.page, this.searchText, this.statusSelected)
  }
}
</script>

<style lang="scss" scoped>
  .section {
    width: 100%;
    min-height: calc(100vh - 90px);
    padding: 0;
  }
  .input {
    font-size: 14px;
  }
  .field.is-grouped {
    display: flex;
    justify-content: flex-end;
  }

  .icon_table_action {
    max-width: 15px;
    margin: 5px;
  }
  .icon_small {
    max-width: 22px;
    margin: 5px;
    cursor: pointer;
  }
  .icon_table_content {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
</style>
